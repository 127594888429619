import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../../../DownloadUserData/Loader";
import QuestionService from '../../../../services/mocks/QuestionService';
import { useHistory } from "react-router-dom";
import '../../../../App.css';
const moment = require('moment');

const tm = require('markdown-it-texmath');
const md = require('markdown-it')({
    html: true,
    typographer: true
}).use(tm, { engine: require('katex'),
delimiters: 'dollars',
katexOptions: { macros: {"\\RR": "\\mathbb{R}"} } });


function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GetAllMCQTypeQuestions(props) {
	const history = useHistory();
	const [questions, setQuestions] = useState([]);
	const [open, setOpen] = React.useState(false);
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [loaderAt, setLoaderAt] = useState(-1);
	const [questionSetId, setQuestionSetId] = useState("");
	const [emptyQuestions, setEmptyQuestions] = useState(false);
	const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);

	const handleClose = () => {
		setStatus(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const GetAllQuestions = async () => {
		try {
			const response = await QuestionService.GetAllQuestionsOfAQuestionSet(props.questionSetSlug, 'MCQ');
			if (response && response.length > 0) {
				setQuestions(response);
				setQuestionSetId(response[0].question_set._id);
			} else {
				setEmptyQuestions(true);
				console.log('Exception in fetching all questions from a question set!');
			}
		} catch (error) {
			console.log(error);
		}
	};

	const updateFlags = async (index, doPublish) => {
		try {
			setLoaderAt(index);
			const questionsResponse = await QuestionService.PublishOrUnpublishAQuestion(questionSetId, questions[index]._id, doPublish);
			const questionsDup = questions.slice();
			questions[index].flags.draft = !doPublish;
			setQuestions(questionsDup);
			setLoaderAt(-1);
			setStatus(true);
			setMessage(doPublish ? `Published Successfully!` : 'Unpublished Successfully!');
			setStatusLoader(false);
		} catch (error) {
			console.log(error);
		}
	}


	useEffect(() => {
		GetAllQuestions();
	}, []);


	return (
		<React.Fragment>
			<div>
				<Row>
					<Col>

						<Link
							to={`/createMCQTypeQuestion/${props.questionSetSlug}`}
						>
							<ButtonToggle
								color="dark"
								style={{
									paddingLeft:
										"30px",
									paddingRight:
										"30px",
									margin: "15px",
									float: 'right'
								}}
							>
								Add Question
							</ButtonToggle>
						</Link>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<>
							{status && (
								<Snackbar
									open={status}
									autoHideDuration={5000}
									onClose={handleClose}
								>
									<>
										<Alert
											onClose={handleClose}
											severity="success"
										>
											{messageData}
										</Alert>
									</>
								</Snackbar>
							)}

							{end && (
								<Snackbar
									open={end}
									onClose={handleCloseEnd}
								>
									<>
										<Alert
											onClose={handleCloseEnd}
											severity="success"
										>
											{messageData}
										</Alert>
									</>
								</Snackbar>
							)}

							{
								questions.length > 0
									?
									(
										questions.map((question, index) => (
											<Card key={question.slug} key={index}>
												<Row>
													<Col lg={9}>
														<CardBody>
															{" "}
															{/* <h4>
																{question.title}
															</h4> */}
															 <p style={{fontWeight:"600",color:"#343a40",fontSize:"1.125rem"}} dangerouslySetInnerHTML={{
                                                                __html:md.render(question.title)
                                                            }}></p>
															{" "}
															<b>Description: </b><span>{question.description}</span>
															<br/>
															<b>Options: </b>
															<br/>
															{
																question.options.length > 1 && (
																	question.options.map((option, index) => (
																		<Row className="ml-1" key={index}>
																			<span>{index + 1}</span> <span style={option.is_correct ? { color: "green", marginLeft: '15px' } : { color: "black", marginLeft: '15px' }} 
																				dangerouslySetInnerHTML={{
																					__html:md.render(option.option)
																				}}></span><br />
																		</Row>
																	))
																)
															}
															{" "}
															<br />
															<b>Diffculty: </b><span>{question.difficulty}</span>
															<br />
															<b>Score: </b><span>{question.score || '0'}</span>
															<br />
															<b>Duration: </b><span>{question.duration ? `${question.duration} seconds`: 'N/A' }</span>
															<br />
															<b>Skills: </b><span>{question?.skills?.toString()}</span>
															<br />
															<b>Created At: </b><span>{question.timestamps && question.timestamps.created_at ? moment(question.timestamps.created_at).format('MMMM Do, YYYY  h:mma') : 'N/A'}</span>
														</CardBody>
													</Col>

													<Col
														lg={3}
														style={{
															textAlign: "center",
														}}
													>
														<CardBody
															style={{
																paddingLeft: "0px",
																paddingRight: "0px",
															}}
														>

															{
																index === loaderAt
																	?
																	(
																		<div style={{ margin: "auto", textAlign: 'left' }}>
																			<Loader />
																		</div>
																	)
																	:
																	(
																		<>
																			<ButtonToggle
																			className={question.flags.draft ? 'publish' : 'unpublish'}
																				onClick={() => updateFlags(index, question.flags.draft ? true : false)}
																			>
																				{question.flags.draft ? 'PUBLISH' : 'UNPUBLISH'}
																			</ButtonToggle>

																			<Link
																				to={`/updateMCQTypeQuestion/${props.questionSetSlug}/${question.slug}`}
																			>
																				<ButtonToggle
																					color="danger"
																					style={{
																						paddingLeft:
																							"30px",
																						paddingRight:
																							"30px",
																						margin: "15px"
																					}}
																				>
																					Edit
																					</ButtonToggle>
																			</Link>
																		</>
																	)
															}

														</CardBody>
													</Col>
												</Row>
											</Card>
										))
									)
									:
									(
										<>
										{
											emptyQuestions 
											? 
											<h4 style={{ textAlign: "center" }}>No Questions found</h4>
											:
											<div style={{ textAlign: "center" }}>
												<Loader />
											</div>
										}
										</>
									)
							}
						</>
					</Col>
				</Row>
			</div>

		</React.Fragment>
	);
}
